var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"server-items-length":1,"mustSort":"","loading":_vm.loading,"hide-default-footer":"","loading-text":"Prosím čekejte ...","no-data-text":"Zatím tady nic není"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Klienti")]),_c('v-spacer'),_c('v-spacer'),(
                        _vm.currentUser &&
                        _vm.currentUser.roles.indexOf('admin') != -1 ||
                        _vm.currentUser.roles.indexOf('invoice') != -1
                    )?_c('v-autocomplete',{staticClass:"mx-5 shrink",attrs:{"prefix":"","prepend-inner-icon":"mdi-account","hide-details":"","single-line":"","items":_vm.saleUsers},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}}):_vm._e(),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchActive),expression:"searchActive"}],staticClass:"shrink",attrs:{"value":_vm.searchTerm,"label":"Hledat","hide-details":"","single-line":""},on:{"change":_vm.searchInput}}),_c('v-btn',{attrs:{"icon":"","disabled":_vm.loading},on:{"click":_vm.toggleSearch}},[(!_vm.searchActive)?_c('v-icon',[_vm._v("mdi-magnify")]):_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"icon":"","color":"secondary","disabled":_vm.loading && null == _vm.userId},on:{"click":_vm.addCustomer}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-divider')]},proxy:true},{key:"item.billingaddress.address",fn:function(ref){
                    var item = ref.item;
return [_c('AddressViewer',{attrs:{"type":"oneline","address":item.billingAddress.address}})]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.openDetailDialog(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.openDeleteConfirmationDialog(item)}}},[_vm._v(" mdi-delete ")])]}}])},[_c('template',{slot:"footer"},[_c('v-divider'),_c('div',{staticClass:"text-center"},[_c('v-btn-toggle',{attrs:{"dense":"","group":""}},[_c('v-btn',{attrs:{"disabled":!this.canPrev},on:{"click":_vm.goToPrevious}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-btn',{attrs:{"disabled":!this.canNext},on:{"click":_vm.goToNext}},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)],2),(_vm.currentCustomer)?_c('DetailDialog',{attrs:{"saveHandler":_vm.saveCustomerHandler,"title":_vm.currentCustomer.name,"originalItem":_vm.currentCustomer},scopedSlots:_vm._u([{key:"content",fn:function(ref){
                    var item = ref.item;
return [_c('CustomerEditor',{attrs:{"customer":item}})]}}],null,false,1460163110),model:{value:(_vm.detailDialog),callback:function ($$v) {_vm.detailDialog=$$v},expression:"detailDialog"}}):_vm._e(),(_vm.currentCustomer)?_c('ConfirmationDialog',{attrs:{"title":'Potvrzení smazání',"backButton":'Zavřít',"confirmButton":'Smazat'},on:{"confirm":function($event){return _vm.deleteCustomer(_vm.currentCustomer)}},model:{value:(_vm.deleteConfirmationDialog),callback:function ($$v) {_vm.deleteConfirmationDialog=$$v},expression:"deleteConfirmationDialog"}},[_c('div',[_c('p',{staticClass:"font-weight-regular py-2 my-0"},[_vm._v(" Opravdu chcete smazat tohoto klienta? ")]),_c('p',{staticClass:"font-weight-black py-2 my-0"},[_vm._v(" "+_vm._s(_vm.currentCustomer.name)+" ")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }