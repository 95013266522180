
















import Vue from "vue";
import { mapState } from "vuex";
import Customers from "../components/Customers.vue";

interface State {
    window: any | null;
}

export default Vue.extend({
    name: "CustomersView",
    components: { Customers },
    data: (): State => {
        return {
            window: {
                width: 0,
                height: 0,
            },
        };
    },
    computed: {
        ...mapState("user", ["currentUser"]),
        customersPerPage(): number {
            const rowHeightApproxPlusReserve = 50;
            const otherHeights = 4 * 48; // nav + table header + table footer + paddings
            return Math.floor(
                (this.window.height - otherHeights) / rowHeightApproxPlusReserve
            );
        },
    },

    methods: {
        handleResize(): any {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },

    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
});
