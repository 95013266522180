








































































































































import Vue from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { Customer } from "../models/customer";
import { User } from "../models/user";
import AddressViewer from "./AddressViewer.vue";
import CustomerEditor from "./CustomerEditor.vue";
import ConfirmationDialog from "./ConfirmationDialog.vue";
import DetailDialog from "./DetailDialog.vue";
import firebase from "firebase/app";
import { db } from "../firebase";
interface State {
    headers: any[];
    searchTerm: string | null;
    currentCustomer: any;
    sortBy: string;
    sortDesc: boolean;
    searchActive: boolean;
    detailDialog: boolean;
    deleteConfirmationDialog: boolean;
    saleUsers: any[];
    userId: string | null;
}

export default Vue.extend({
    name: "Customers",

    components: {
        AddressViewer,
        CustomerEditor,
        ConfirmationDialog,
        DetailDialog,
    },

    props: {
        type: String,
        itemsPerPage: Number,
    },

    data: (): State => {
        const headers = [
            { text: "Název", value: "name" },
            {
                text: "Adresa",
                value: "billingaddress.address",
                sortable: false,
            },
            { text: "Telefon", value: "phone", sortable: false },
            { text: "IČO", value: "billingAddress.subId", sortable: false },
            { text: "DIČ", value: "billingAddress.vatId", sortable: false },
            {
                text: "",
                value: "actions",
                filterable: false,
                sortable: false,
                align: "end",
            },
        ];
        return {
            headers: headers,

            currentCustomer: null,

            searchTerm: null,
            searchActive: false,

            sortBy: "name",
            sortDesc: false,

            detailDialog: false,
            deleteConfirmationDialog: false,

            saleUsers: [],
            userId: null,
        };
    },

    computed: {
        ...mapGetters("customers", ["canNext", "canPrev", "loading"]),
        ...mapState("customers", ["items"]),
        ...mapState("user", ["currentUser"]),
    },

    watch: {
        currentUser: {
            immediate: true,
            async handler() {
                await this.setCurrentUserId();
            },
        },
        sortBy: {
            async handler(value: string) {
                if (value == "billingaddress.address") {
                    this.sortBy = "billingAddress.address.city";
                } else {
                    await this.orderBy();
                }
            },
        },
        sortDesc: {
            async handler(value: boolean) {
                await this.orderBy();
            },
        },
        searchTerm: {
            async handler() {
                await this.fetch();
            },
        },
        userId: {
            async handler() {
                await this.fetch();
            },
        },
        itemsPerPage: {
            async handler() {
                await this.fetch();
            },
        },
    },

    methods: {
        ...mapActions("alerts", ["addAlert"]),

        searchInput(input: string) {
            this.searchTerm = input;
        },
        toggleSearch() {
            this.searchTerm = null;
            this.searchActive = !this.searchActive;
        },

        async setCurrentUserId() {
            try {
                if (this.currentUser) {
                    if (this.currentUser.roles.indexOf("admin") == -1 && this.currentUser.roles.indexOf("invoice") == -1) {
                        this.userId = this.currentUser.id;
                    } else {
                        await this.$store.dispatch(
                            "user/fetchUsersByRole",
                            "sales"
                        );
                        this.saleUsers = this.$store.state.user.users.map(
                            (user: User) => ({
                                value: user.id,
                                text: user.name,
                            })
                        );
                        this.userId = this.saleUsers[0].value;
                    }
                    await this.fetch();
                }
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            }
        },

        async goToPrevious() {
            try {
                await this.$store.dispatch("customers/goToPrevious");
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            }
        },

        async goToNext() {
            try {
                await this.$store.dispatch("customers/goToNext");
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            }
        },

        async orderBy() {
            try {
                await this.$store.dispatch("customers/orderBy", {
                    sortBy: this.sortBy,
                    sortDesc: this.sortDesc,
                });
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            }
        },

        async fetch() {
            try {
                if (this.userId) {
                    await this.$store.dispatch("customers/fetch", {
                        itemsPerPage: this.itemsPerPage,
                        filters: {
                            searchTerm: this.searchTerm,
                            userId: this.userId,
                        },
                    });
                }
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Chyba načítání: " + error.message,
                });
            }
        },

        addCustomer() {
            this.openDetailDialog(Customer.create(this.userId!));
        },

        async saveCustomerHandler(customer: Customer): Promise<void> {
            const snapshot = await db
                .collection("customers")
                .where(
                    "billingAddress.subId",
                    "==",
                    customer.billingAddress.subId
                )
                .where('userId', '==', customer.userId)
                .get();
            if (!customer.id && !snapshot.empty) {
                throw new Error(`Zákazník s IČO ${customer.billingAddress.subId} již existuje.`);
            }

            if (customer.id) {
                await this.$store.dispatch("customers/update", customer);
            } else {
                await this.$store.dispatch("customers/create", customer);
            }
        },

        async deleteCustomer(customer: Customer) {
            try {
                await this.$store.dispatch("customers/delete", customer);
            } catch (error) {
                this.addAlert({
                    type: "error",
                    color: "red",
                    message: "Smazání se nepodařilo: " + error.message,
                });
            }
        },

        openDetailDialog(item: Customer) {
            this.currentCustomer = item;
            this.detailDialog = true;
        },

        openDeleteConfirmationDialog(item: Customer) {
            this.currentCustomer = item;
            this.deleteConfirmationDialog = true;
        },
    },
});
